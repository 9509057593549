import { createRouter, createWebHistory } from 'vue-router'
import { provide } from 'vue'

import routes from '@/router/routes'
import AuthenticationStore from '@/stores/AuthenticationStore'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

provide('store', AuthenticationStore)

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (AuthenticationStore.state.authenticated) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
