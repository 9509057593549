import axios from 'axios'
import { NewRoleRequest, RoleAccessRightsRequest, RoleRequest, UpdateRoleRequest } from '@/models/api'

export async function getRoles() {
  return await axios.get('/api/platform/admin/roles/list')
}

export async function getRoleModuleAccess({ roleId }: RoleRequest) {
  return await axios.get('/api/platform/admin/role/' + roleId + '/module/access/list')
}

export async function getRoleAccessRights({ roleId }: RoleRequest) {
  return await axios.get('/api/platform/admin/role/' + roleId + '/access/rights/list')
}

export async function addRole({ title }: NewRoleRequest) {
  const data = new FormData()
  data.append('title', title)
  return await axios.post('/api/platform/admin/role/add', data)
}

export async function updateRole({ title, roleId }: UpdateRoleRequest) {
  const data = new FormData()
  data.append('role_title', title)
  data.append('role_id', roleId.toString())
  return await axios.post('/api/platform/admin/role/update', data)
}

export async function deleteRole({ roleId }: RoleRequest) {
  const data = new FormData()
  data.append('role_id', roleId.toString())
  return await axios.post('/api/platform/admin/role/delete', data)
}

export async function setRoleAccessRights({ roleId, accessKey, value }: RoleAccessRightsRequest) {
  const data = new FormData()
  data.append('role_id', roleId.toString())
  data.append('access_key', accessKey)
  data.append('value', value.toString())
  return await axios.post('/api/platform/admin/role/access/rights/set', data)
}

export async function setRoleModuleAccess({ roleId, accessKey, value }: RoleAccessRightsRequest) {
  const data = new FormData()
  data.append('role_id', roleId.toString())
  data.append('access_key', accessKey)
  data.append('value', value.toString())
  return await axios.post('/api/platform/admin/role/module/access/set', data)
}
