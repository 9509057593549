import { reactive } from 'vue'
import axios from 'axios'
import router from '@/router'

//#region type definitions
export type ApplicationStoreStateType = {
  authenticated: boolean
  token: string | null
}

export type ApplicationStoreMethodsType = {
  initAuthentication: () => void
  logout: () => void
  setAxiosAuthenticationHeaders: (cid: number, sid: number, uaid: number) => void
  setAuthenticationHeaders: (cid: number, sid: number, uaid: number) => void
}

export type ApplicationStoreGetterType = []

export type ApplicationStoreType = {
  state: ApplicationStoreStateType
  methods: ApplicationStoreMethodsType
  getters: ApplicationStoreGetterType
}
//#endregion

const state: ApplicationStoreStateType = reactive({
  authenticated: false,
  token: '',
})

const methods: ApplicationStoreMethodsType = {
  initAuthentication() {
    const c = document.cookie
    state.authenticated = document.cookie.includes('auth=')

    const cid: number = sessionStorage.getItem('cid') == null ? 0 : parseInt(sessionStorage.getItem('cid') as string)
    const sid: number = sessionStorage.getItem('sid') == null ? 0 : parseInt(sessionStorage.getItem('sid') as string)
    const caid: number = sessionStorage.getItem('caid') == null ? 0 : parseInt(sessionStorage.getItem('caid') as string)

    if (sid !== 0) {
      methods.setAxiosAuthenticationHeaders(cid, sid, caid)
    }
  },
  setAuthenticationHeaders(cid: number, sid: number, caid: number) {
    methods.setAxiosAuthenticationHeaders(cid, sid, caid)
    sessionStorage.setItem('cid', cid.toString())
    sessionStorage.setItem('sid', sid.toString())
    sessionStorage.setItem('caid', caid.toString())
  },
  setAxiosAuthenticationHeaders(cid: number, sid: number) {
    axios.defaults.headers.common['X-SID'] = sid

    if (cid !== 0) {
      axios.defaults.headers.common['X-CID'] = cid
    }
  },
  async logout() {
    // remove all cookies
    const cookies = document.cookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const eqPos = cookie.indexOf('=')
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
    }

    // clear variables stored in browser session storage
    sessionStorage.clear()
    router.push('/login')
  },
}

const getters: ApplicationStoreGetterType = []

export default {
  state,
  methods,
  getters,
}
