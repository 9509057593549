import axios from 'axios'
import {
  ClientAccessRightsRequest,
  ClientRequest,
  ClientWithPaginationRequest,
  ClientsRequest,
  NewClientRequest,
  NewUserRequest,
} from '@/models/api'

export async function getClients({ page, direction, showArchived }: ClientsRequest) {
  return await axios.get('/api/platform/admin/clients/list/' + page + '/' + direction + '/' + showArchived)
}

export async function getAllActiveClients() {
  return await axios.get('/api/platform/admin/clients/list')
}

export async function getClientAccessRights({ groupId }: ClientRequest) {
  return await axios.get('/api/platform/admin/client/' + groupId + '/access/list')
}

export async function getClientUsers({ groupId, page, direction }: ClientWithPaginationRequest) {
  return await axios.get('/api/platform/admin/client/' + groupId + '/users/list/' + page + '/' + direction)
}

export async function setClientAccessRight({ groupId, accessKey, value }: ClientAccessRightsRequest) {
  const data = new FormData()
  data.append('group_id', groupId.toString())
  data.append('access_key', accessKey)
  data.append('value', value.toString())
  return await axios.post('/api/platform/admin/client/access/set', data)
}

export async function archiveClient({ groupId }: ClientRequest) {
  const data = new FormData()
  data.append('group_id', groupId.toString())
  return await axios.post('/api/platform/admin/client/archive', data)
}

export async function activateClient({ groupId }: ClientRequest) {
  const data = new FormData()
  data.append('group_id', groupId.toString())
  return await axios.post('/api/platform/admin/client/activate', data)
}

export async function createNewClient({
  companyName,
  adminUserName,
  adminUserEmail,
  adminUserPhoneNumber,
}: NewClientRequest) {
  const data = new FormData()
  data.append('company_name', companyName)
  data.append('admin_user_name', adminUserName)
  data.append('admin_user_email', adminUserEmail)
  data.append('admin_user_phone_number', adminUserPhoneNumber)
  return await axios.post('/api/platform/admin/client/new', data)
}
