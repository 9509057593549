import Notification from '@/plugins/notify/Notification.vue'

export const notify = {
  create: (message: string) => {
    const div = document.createElement('div')
    div.innerHTML =
      '<div id="notify" class="relative" style="z-index:10000;"><div class="bg-red-500 font-bold fixed w-full py-3 top-0 left-0 z-100 text-center text-xs text-white px-4"><p>' +
      message +
      '</p></div></div>'
    document.body.appendChild(div)
    setTimeout(notify.destroy, 5000)
  },
  destroy: (): void => {
    const element = document.getElementById('notify')
    if (element != null) element.innerHTML = ''
  },
}
