import axios from 'axios'
import {
  ClientAccessRightsRequest,
  ClientRequest,
  ClientWithPaginationRequest,
  ClientsRequest,
  NewClientRequest,
  NewUserRequest,
} from '@/models/api'

export async function getBosQuestions() {
  return await axios.get('/api/platform/admin/bos/questions/get')
}

export async function deactivateBosQuestion(question_id: string, question_code: string) {
  const data = new FormData()
  data.append('question_id', question_id)
  data.append('question_code', question_code)
  return await axios.post('/api/platform/admin/bos/question/delete', data)
}

export async function updateNormKey(norm_key: string, updated_key: string) {
  const data = new FormData()
  data.append('norm_key', norm_key)
  data.append('updated_key', updated_key)
  return await axios.post('/api/platform/admin/bos/norm/key/update', data)
}
