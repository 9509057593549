/* eslint-disable @typescript-eslint/no-explicit-any */
import { notify } from '@/plugins/notify'
import { VueElement } from 'vue'

const notification = {
  error(message: string): void {
    notify.create(message)
  },
}

export default notification
