import axios from 'axios'
import { AddSuperAdminRequest, SuperAdminRequest } from '@/models/api'

export async function getSuperAdmins() {
  return await axios.get('/api/platform/admin/management/admin/list')
}

export async function addSuperAdmin({ email, name }: AddSuperAdminRequest) {
  const data = new FormData()
  data.append('email', email)
  data.append('name', name)
  return await axios.post('/api/platform/admin/management/admin/add', data)
}

export async function deleteSuperAdmin({ id }: SuperAdminRequest) {
  const data = new FormData()
  data.append('id', id.toString())
  return await axios.post('/api/platform/admin/management/admin/delete', data)
}
