import { createApp, provide } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import initAxios, { createAxiosResponseInterceptor } from './middleware/axiosMiddleware'

import AuthenticationStore from './stores/AuthenticationStore'
import Loader from './components/global/Loader.vue'
import Modal from './components/global/Modal.vue'
import SavingProgress from './components/global/SavingProgress.vue'
import ErrorMessage from './components/global/ErrorMessage.vue'
import Toggle from './components/global/Toggle.vue'

import './styles/tailwind.css'

const app = createApp(App)

provide('store', AuthenticationStore)
AuthenticationStore.methods.initAuthentication()

app.use(router)
app.component('Loader', Loader)
app.component('Modal', Modal)
app.component('SavingProgress', SavingProgress)
app.component('ErrorMessage', ErrorMessage)
app.component('Toggle', Toggle)
initAxios()
createAxiosResponseInterceptor()

app.mount('#app')
