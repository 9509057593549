import axios from 'axios'
import {
  ClientUserRequest,
  EmailRequest,
  NewUserRequest,
  UserAdminStatusRequest,
  UserClientsRequest,
  UsersRequest,
  UserAccessRightRequest,
} from '@/models/api'

export async function getUserAdminStatus(userId: number) {
  return await axios.get('/api/platform/admin/user/' + userId + '/admin/status')
}

export async function getUsers({ page, direction, showDisabled }: UsersRequest) {
  return await axios.get('/api/platform/admin/users/list/' + page + '/' + direction + '/' + showDisabled)
}

export async function getUserClients({ email, page, direction }: UserClientsRequest) {
  return await axios.get('/api/platform/admin/user/' + email + '/clients/' + page + '/' + direction)
}

export async function getUserAccessRights(userId: number) {
  return await axios.get('/api/platform/admin/user/' + userId + '/access/rights')
}

export async function getAvailableClientsForUser(userEmail: string) {
  return await axios.get('/api/platform/admin/user/' + userEmail + '/clients/available')
}

export async function updateUserAdminStatus({ userId, status }: UserAdminStatusRequest) {
  const data = new FormData()
  data.append('user_id', userId.toString())
  data.append('admin_status', status.toString())
  return await axios.post('/api/platform/admin/user/admin/status', data)
}

export async function updateUserAccess({ userId, accessRightKey, status }: UserAccessRightRequest) {
  const data = new FormData()
  data.append('user_id', userId.toString())
  data.append('access_right_key', accessRightKey)
  data.append('status', status.toString())
  return await axios.post('/api/platform/admin/user/access/set', data)
}

export async function deleteUser({ userId, groupId }: ClientUserRequest) {
  const data = new FormData()
  data.append('user_id', userId.toString())
  data.append('group_id', groupId.toString())
  return await axios.post('/api/platform/admin/user/delete', data)
}

export async function activateUser({ userId, groupId }: ClientUserRequest) {
  const data = new FormData()
  data.append('user_id', userId.toString())
  data.append('group_id', groupId.toString())
  return await axios.post('/api/platform/admin/user/activate', data)
}

export async function disableAllUserAccount({ email }: EmailRequest) {
  const data = new FormData()
  data.append('user_email', email)
  return await axios.post('/api/platform/admin/user/disable/global', data)
}

export async function inviteNewUser({ clientId, userName, userEmail }: NewUserRequest) {
  const data = new FormData()
  data.append('group_id', clientId.toString())
  data.append('user_name', userName)
  data.append('user_email', userEmail)
  return await axios.post('/api/platform/admin/client/user/new', data)
}
