import { RouteRecordRaw } from 'vue-router'

import Login from '@/views/Login.vue'
import Clients from '@/views/Clients.vue'
import Users from '@/views/Users.vue'
import Roles from '@/views/Roles.vue'
import Management from '@/views/Management.vue'
import RmDeletedOrgsManagement from '@/views/modules/RmDeletedOrgs.vue'
import Bos from '@/views/Bos.vue'
import Norms from '@/views/Norms.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/callback/azuread/userAuth',
    name: 'LoginLanding',
    component: Login,
  },
  {
    path: '/',
    name: 'Clients',
    component: Clients,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/roles',
    name: 'Roles',
    component: Roles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/management',
    name: 'Management',
    component: Management,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/modules/rm/organisations',
    name: 'RmDeletedOrgsManagement',
    component: RmDeletedOrgsManagement,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/bos',
    name: 'Bos',
    component: Bos,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/norms',
    name: 'Norms',
    component: Norms,
    meta: {
      requiresAuth: true,
    },
  },
]
export default routes
