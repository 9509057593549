import notification from '@/util/notification'
import axios, { AxiosResponse, AxiosProxyConfig } from 'axios'
import { AxiosError } from 'axios'
import router from '../router'
import AuthenticationStore from '../stores/AuthenticationStore'

export default function initAxios() {
  // X-TLC header needs to be set, if not -> POST and PUT requests fail
  axios.defaults.headers.common['X-TLC'] = 1
}

export function createAxiosResponseInterceptor() {
  axios.interceptors.request.use(request => {
    return request
  })

  axios.interceptors.response.use(
    function (response: AxiosResponse) {
      // Catch all backend errors and show notification
      if (response.data && response.data.status && response.data.status.toLowerCase() === 'error') {
        //global error catch
        notification.error(response.data.message)
        return Promise.reject()
      }
      return response
    },
    function (error: AxiosError) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      if (error instanceof Error) {
        if (error?.response?.status === 401) {
          // AuthenticationStore.methods.logout()
        }
        // todo add offline network unavailable message
      } else {
        console.error(error)
      }
      return Promise.reject(error.response)
    },
  )
}
