import axios from 'axios'

export async function getDeletedOrgs() {
  return await axios.get('/api/platform/admin/rm/organisations/deleted/get')
}

type RestoreDeletedOrgRequest = {
  orgId: number
  groupId: number
}

export async function restoreDeletedOrg({ orgId, groupId }: RestoreDeletedOrgRequest) {
  const data = new FormData()
  data.append('org_id', orgId.toString())
  data.append('group_id', groupId.toString())
  return await axios.post('/api/platform/admin/rm/organisation/restore', data)
}
