import axios from 'axios'
import { LoginCredentialsRequestParam } from '@/models/api'

export async function loginUser(code: string) {
  const data = new FormData()
  data.append('code', code)
  return await axios.post('/api/platform/admin/login', data)
}

export async function getMicrosoftOauthUrl(code: string) {
  return await axios.get('/api/platform/admin/login')
}
